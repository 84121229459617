<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试成绩查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px;">
            <div title="考生姓名" class="searchboxItem ci-full">
              <span class="itemLabel">考生姓名:</span>
              <el-input v-model="searchForm.userName" clearable size="small" placeholder="请输入考生姓名"></el-input>
            </div>
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input v-model="searchForm.examinationName" clearable size="small" placeholder="请输入考试名称"></el-input>
            </div>
            <div title="阅卷状态" class="searchboxItem ci-full">
              <span class="itemLabel">阅卷状态:</span>
              <el-select v-model="searchForm.scoringState" clearable size="small" placeholder="请选择阅卷状态">
                <el-option v-for="item in scoringStateList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="searchForm.occupationId" filterable remote clearable size="small"
                :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:</span>
              <el-select v-model="searchForm.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div title="考试时间" class="searchboxItem ci-full">
              <span class="itemLabel">考试时间:</span>
              <el-date-picker clearable size="small" v-model="searchForm.times" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportSeeExaminationRoom">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" prop="userName" show-overflow-tooltip />
              <el-table-column label="考试名称" align="center" prop="examinationName" show-overflow-tooltip />
              <el-table-column label="考试科目" align="center" prop="subjectCode" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                </template>
              </el-table-column>
              <el-table-column label="培训工种" align="center" prop="occupationName" show-overflow-tooltip />
              <el-table-column label="考试时间" align="center" prop="examTime" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{ row.startTime }}~{{ row.endTime }}
                </template>
              </el-table-column>
              <el-table-column label="阅卷状态" align="center" prop="scoringState" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_SCORING_STATE", scope.row.scoringState) }}
                </template>
              </el-table-column>
              <el-table-column label="分数" align="center" prop="paperScore" show-overflow-tooltip />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_scoreInquiry",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        userName: "", // 考生姓名
        examinationName: "", // 考试名称
        scoringState: "", // 阅卷状态
        occupationId: "", // 培训工种
        subjectCode: "", // 考试科目
        times: [], // 考试时间
      },
      // 阅卷状态 - 下拉数据
      scoringStateList: [],
      // 培训工种 - 下拉数据
      occupation: [],
      // 考试科目 - 下拉数据
      subjectCodeList: [],
    };
  },
  created() {
    this.dataDictionary();
  },
  computed: {

  },
  methods: {
    // 获取 - 培训工种
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 阅卷状态
      const arr1 = this.$setDictionary("EXAMINATION_SCORING_STATE", "list");
      for (const key in arr1) {
        this.scoringStateList.push({
          value: key,
          label: arr1[key],
        });
      }
      // 考试科目
      const arr2 = this.$setDictionary("EA_EXAM_SUBJECT_CODE", "list");
      for (const key in arr2) {
        this.subjectCodeList.push({
          value: key,
          label: arr2[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      if(this.searchForm.times){
        params.startTime = this.searchForm.times[0];
        params.endTime = this.searchForm.times[1];
      }
      this.doFetch(
        {
          url: "/biz/examination/performance/comp/pageList",
          params,
          pageNum,
        }, true, 6
      );
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
        ...this.searchForm
      };
      if(this.searchForm.times){
        params.startTime = this.searchForm.times[0];
        params.endTime = this.searchForm.times[1];
      }
      this.$post(
        "/biz/examination/performance/comp/export",
        params, 3000, true, 6
      )
        .then((res) => {
          if (res.status == '0') {
            window.open(res.message)
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>